<template>
    <div class="border-t pt-4 px-4 border-bondi-blue font-roboto-thin text-md">&copy; {{ currentYear }} All Rights
        Reserved,
        ECSA-HC
    </div>
</template>

<script>
export default {
    name: "FooterView"
}
</script>
